import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import imageCompression from 'browser-image-compression';

const firebaseConfig = {
    apiKey: "AIzaSyA-D7cW2oJ4SARWxJKTXiw3lqcldsl8u4g",
    authDomain: "msichana-ni-tai.firebaseapp.com",
    projectId: "msichana-ni-tai",
    storageBucket: "msichana-ni-tai.appspot.com",
    messagingSenderId: "17108771193",
    appId: "1:17108771193:web:6891403ce932098209cfa8",
    measurementId: "G-93NRL040M3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();


Vue.config.productionTip = false

new Vue({
    store,
    router,
    vuetify,
    analytics,
    db, imageCompression,
    
    render: h => h(App)
}).$mount('#app')