<template>
  <v-btn
    id="create-button"
    v-if="isAdmin"
    color="secondary"
    fixed
    right
    bottom
    tile
    :to="to"
    class="text-capitalize"
  >
    <v-icon>mdi-pencil</v-icon>
    {{ label }}</v-btn
  >
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: { to: String, label: String },
  data() {
    return {
      createArtileText: "Create Article",
    };
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
      isAdmin: "isAdmin",
    }),
  },
};
</script>

