<template>
  <div id="comments">
    <v-row justify="center">
      <v-col sm="8" lg="6">
        <v-subheader v-if="comments.length > 0">{{ commentsText }}</v-subheader>

        <v-row
          v-for="(comment, i) in comments"
          :key="i"
          align="start"
          class="mx-1"
        >
          <v-col cols="1">
            <v-avatar size="40">
              <v-img :src="comment.author_image_url" alt="Author Image"></v-img>
            </v-avatar>
          </v-col>
          <v-col>
            <v-row class="pl-3" align="center"
              ><p class="font-weight-bold caption">{{ comment.author }}</p>
              <p class="caption font-weight-light text-lighten-5 mx-2">
                {{ time(comment.created_at) }}
              </p></v-row
            >
            <p
              v-html="comment.text"
              class="grey--text text-darken-5 font-italic"
            ></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
} from "@firebase/firestore";
export default {
  name: "Comments",
  mounted() {
    this.getComments();
  },
  data() {
    return {
      loading: false,
      comments: [],
      commentsText: "Comments",
    };
  },
  methods: {
    async getComments() {
      this.loading = true;
      const db = getFirestore();
      const id = this.$router.currentRoute.query.id;
      const ref = collection(db, `blogs/${id}/comments`);
      const q = query(ref, orderBy("created_at", "desc"));
      try {
        const snapshot = await getDocs(q);
        snapshot.forEach((doc) => {
          let comment = doc.data();
          comment.id = doc.id;
          this.comments.push(comment);
        });
        this.loadig = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    time(time) {
      try {
        const createdAt = time.toDate().toDateString();
        return createdAt;
      } catch (error) {
        console.log(error);
        return "";
      }
    },
  },
};
</script>


