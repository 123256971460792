<template>
  <div id="blogs">
    <Hero :title="title" :description="description" />
    <Loading :loading="loading" />
    <v-container class="my-6">
      <v-row justify="center">
        <v-col v-for="blog in blogs" :key="blog.id" lg="4" sm="10">
          <BlogCard :blog="blog" />
        </v-col>
      </v-row>
      <CreateButton to="/create-blog" :label="createBlogText" />
    </v-container>
  </div>
</template>

<script>
import Hero from "@/layouts/Hero";
import BlogCard from "@/layouts/BlogCard";
import Loading from "@/layouts/Loading";
import CreateButton from "@/layouts/create-button";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from "@firebase/firestore";
export default {
  name: "Articles",
  components: { Hero, BlogCard, Loading, CreateButton },
  mounted() {
    this.getBlogs();
  },
  data() {
    return {
      title: "Blogs",
      description:
        "Join the discussion, here we discuss from a wide variety of topics.",
      loading: true,
      limit: 10,
      blogs: [],
      createBlogText: "Create Blog",
    };
  },

  methods: {
    async getBlogs() {
      this.loading = true;
      const db = getFirestore();
      const ref = collection(db, "blogs");
      const q = query(ref, orderBy("created_at", "desc"), limit(this.limit));
      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let blogDoc = doc.data();
          blogDoc.id = doc.id;
          this.blogs.push(blogDoc);
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>

