import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#05A859',
                secondary: '#F0773D',
                accent: '#05A859',
                error: '#b71c1c',
                black: '#282828'
            },
        },
    },
});