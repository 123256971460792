<template>
  <div id="about">
    <Hero :title="title" :description="description" />

    <v-container grid-list-xs class="my-6">
      <v-row justify="center">
        <v-col class="col-lg-5">
          <h1>{{ movement.title }}</h1>
          <p>{{ movement.description }}</p>
        </v-col>
        <v-col class="col-lg-5">
          <v-img :src="movement.image" alt="About Image"
            ><template v-slot:placeholder> <Placeholder /> </template
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <div grid-list-xs>
      <h1 class="text-center col-lg-6 mx-2 mx-auto">
        {{ organization.title }}
      </h1>
      <v-img :src="organization.image"
        ><template v-slot:placeholder> <Placeholder /> </template
      ></v-img>
      <p class="d-flex text-center col-lg-6 mx-auto">
        {{ organization.description }}
      </p>
      <v-row justify="center">
        <v-btn
          tile
          text
          color="primary"
          class="mb-6 text-capitalize"
          :href="organization.url"
          target="_blank"
          >{{ more }}</v-btn
        >
      </v-row>
    </div>

    <div grid-list-xs class="primary py-6">
      <h1 class="text-center white--text">{{ goalsSection.title }}</h1>
      <p class="text-center white--text">{{ goalsSection.description }}</p>
      <v-container grid-list-xs>
        <v-row justify="center">
          <v-col
            v-for="(goal, i) in goalsSection.goals"
            :key="i"
            :cols="$vuetify.breakpoint.mobile ? 12 : 5"
          >
            <v-icon x-large color="white" class="d-flex">{{
              goal.icon
            }}</v-icon>
            <p class="text-center white--text">{{ goal.text }}</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Hero from "@/layouts/Hero";
import Placeholder from "@/layouts/Placeholder";
export default {
  name: "About",
  components: { Hero, Placeholder },
  data() {
    return {
      title: "About Us",
      description: "Here is all you need to know about us.",
      more: "Learn More",
      movement: {
        title: "About Msichana Ni Tai",
        description:
          "Msichana ni Tai is also known as The Eagle Movement. This movement fights the socialization and internalization that women and girls are weak, that they are chickens, instead it boldly claims that “women and girls are eagles not chickens.”  This Girls Movement follows what the late President Julius Kambarage Nyerere wrote in 1944 while studying at Makerere University.",
        image: require("@/assets/img/msichana_ni_tai_05.jpg"),
      },

      organization: {
        title: "About MikonoYetu",
        description:
          "MikonoYetu means “Joining hands Together” in Kiswahili the national language of Tanzania. MikonoYetu believes that change cannot be brought about by one person or an organization. Instead, it is required we join hands together to create sustainable change in the lives of women. In Tanzania, MikonoYetu is a women-led non-governmental organization based in Mwanza. It was registered in 2011 as a non-profit organization under the Company Ordinance. Due to the change in the laws, on August 10th, 2019, MikonoYetu was registered as a certified non-governmental organization: No. 00NGO/R2/OOO359.",
        image: require("@/assets/img/msichana_ni_tai_16.jpg"),
        url: "https://www.mikonoyetu.co.tz",
      },

      goalsSection: {
        title: "Our Goals",
        description: "This is what we aim to achieve.",
        goals: [
          {
            text: "To create a database of adolescent girls who wish to join the Eagle Movement",
            icon: "mdi-database",
          },
          {
            text: "To challenge traditions and practices that disempower and discriminate girls",
            icon: "mdi-mirror",
          },
          {
            text: "To create awareness on girls human rights",
            icon: "mdi-information",
          },
          {
            text: "To provide a discussion platform for girls to share their views and dreams",
            icon: "mdi-chat",
          },
        ],
      },
    };
  },
  mounted() {
    return {
      goToTop() {
        scrollTo(0, 0);
      },
    };
  },
};
</script>



