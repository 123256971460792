<template>
  <div
    id="home-hero"
    class="primary"
    :style="$vuetify.breakpoint.mobile ? '' : 'height: 80vh'"
  >
    <v-row class="fill-height" justify="center" align="center">
      <v-col :cols="$vuetify.breakpoint.mobile ? 11 : 6">
        <h1
          :style="$vuetify.breakpoint.mobile ? '' : 'font-size: 4em'"
          class="white--text"
        >
          {{ title }}
        </h1>
        <h2 class="col-lg-8 white--text pl-0">
          {{ description }}
        </h2>
        <!-- <p class="col-10 white--text pl-0">{{ aboutText }}</p> -->
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 11 : 5">
        <HeroForm />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeroForm from "@/components/home/HeroForm.vue";
export default {
  name: "HomeHero",
  components: { HeroForm },
  data() {
    return {
      title: "Msichana Ni Tai",
      description:
        "The Eagle Movement for Adolescent Girls. Fighting the socialization and internalization that women and girls are weak.",
      aboutText:
        "This movement fights the socialization and internalization that women and girls are weak, that they are chickens, instead it boldly claims that “women and girls are eagles not chickens.”  This Girls Movement follows what the late President Julius Kambarage Nyerere wrote in 1944 while studying at Makerere University.",

      heroImage: "@/assets/img/msichana_ni_tai_01.jpg",
    
    };
  },
};
</script>

