<template>
  <div class="secondary">
    <v-container class="my-6 py-6">
      <h1 class="text-center white--text mt-6">{{ title }}</h1>
      <p class="text-center white--text">{{ description }}</p>
      <v-row justify="center" class="my-6">
        <v-col v-for="article in articles" :key="article.id" lg="4" sm="12">
          <ArticleCard :article="article" />
        </v-col>
      </v-row>
      <v-row justify="center" class="mb-6">
        <v-btn
          tile
          color="primary"
          elevation="0"
          large
          class="text-capitalize"
          to="/articles"
        >
          {{ viewMoreText }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ArticleCard from "@/layouts/ArticleCard";

import {
  collection,
  query,
  getDocs,
  limit,
  getFirestore,
  orderBy,
} from "firebase/firestore";

export default {
  name: "ArticlesSection",
  components: { ArticleCard },
  data() {
    return {
      title: "Articles",
      description:
        "Take a look a the articles that we share about different topics on the movement.",
      viewMoreText: "View More",
      articles: [],
    };
  },

  mounted() {
    this.getArticles();
  },
  methods: {
    async getArticles() {
      const db = getFirestore();
      const ref = collection(db, "articles");
      const q = query(ref, orderBy("created_at", "desc"), limit(3));
      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let articleDoc = doc.data();
          articleDoc.id = doc.id;

          this.articles.push(articleDoc);
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>


