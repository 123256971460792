<template>
  <v-sheet id="Hero" color="primary" height="30vh">
    <h1 class="text-center white--text pt-6">{{ title }}</h1>
    <p class="text-center white--text col-lg-4 mx-auto">{{ description }}</p>
    
  </v-sheet>
</template>

<script>
export default {
  name: "Hero",
  props: {
    title: String,
    description: String,
  },
};
</script>

<style>
</style>