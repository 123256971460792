<template>
  <div id="loading">
    <v-row v-if="loading" justify="center" class="mt-0">
      <v-progress-linear indeterminate color="secondary "></v-progress-linear>
    </v-row>
  </div>
</template>

<script>
export default {
    name:'Loading',
    props:{loading:Boolean}
};
</script>


