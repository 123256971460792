<template>
  <div id="profile">
    <Hero :title="title" :description="description" />
    <v-container>
      <v-row justify="center" align="center">
        <v-col sm="10" lg="6">
          <v-row justify="center" class="my-2">
            <v-avatar size="100" color="white">
              <img :src="user.photoURL" alt="User Image" />
            </v-avatar>
          </v-row>
          <v-row justify="center" align="center">
            <h2 class="text-center">{{ user.displayName }}</h2>
            <v-btn icon @click="dialog = !dialog">
              <v-icon color="secondary"> mdi-pencil-circle </v-icon>
            </v-btn>
          </v-row>
          <p class="text-center my-1">{{ user.email }}</p>
          <p class="text-center my-1">{{ user.phone }}</p>

          <LogoutButton />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title>
          {{ editProfieText }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            name="name"
            :label="nameLabel"
            id="name"
            clearable
          ></v-text-field>
          <v-text-field
            v-model="phone"
            name="phone"
            :label="phoneLabel"
            id="phone"
            clearable
          ></v-text-field>
          <v-text-field
            v-model="email"
            name="email"
            :label="emailLabel"
            id="email"
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text class="text-capitalize" @click="submit">{{
            submitText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Hero from "@/layouts/Hero";
import LogoutButton from "@/components/profile/LogoutButton";
import { getAuth } from "@firebase/auth";
export default {
  components: { LogoutButton, Hero },
  computed: {
    ...mapGetters({
      user: "currentUser",
    }),
  },
  data() {
    return {
      title: "My Profile",
      description: "View and udpate your profile details",
      dialog: false,
      submitText: "Submit",
      editProfieText: "Edit Profile",
      nameLabel: "Your Name",
      emailLabel: "Your Email",
      phoneLabel: "Your Phone Number",
      name: this.user.displayName,
      phone: this.user.phone,
      email: this.user.email,
    };
  },
  methods: {
    async submit() {
      const auth = getAuth();
      console.log(auth);
    },
  },
};
</script>

