<template>
  <v-card
    id="article-card"
    tile
    :to="{ path: 'article', query: { id: `${article.id}` } }"
    height="56vh"
    class="overflow-hidden"
  >
    <v-img :src="article.image_url ? article.image_url : logo" height="40vh"
      ><template v-slot:placeholder> <Placeholder /> </template>
      <v-row class="fill-height bottom-gradient" align="bottom">
        <!-- <v-card-text v-html="article.text" class="text-truncate"> </v-card-text> -->
      </v-row>
    </v-img>
    <v-card-title primary-title>
      <p style="word-break: normal">
        {{ article.title }}
      </p>
    </v-card-title>
  </v-card>
</template>

<script>
import Placeholder from "@/layouts/Placeholder";

export default {
  name: "ArticleCard",
  components: { Placeholder },
  props: {
    article: Object,
  },
  computed: {
    text() {
      const limit = 300;
      if (this.article.text.length > limit)
        return this.article.text.substring(0, limit);
      else return this.article.text;
    },
  },
  data() {
    return {
      logo: require("@/assets/logo.png"),
    };
  },
};
</script>



