<template>
  <div class="px-0 mx-0">
    <HomeHero />
    <BlogSection />
    <ArticlesSection />
    <AboutSection />
  </div>
</template>

<script>
import HomeHero from "@/components/home/HomeHero";
import AboutSection from "@/components/home/AboutSection";
import ArticlesSection from "@/components/home/ArticlesSection";
import BlogSection from "@/components/home/BlogSection";
export default {
  name: "Home",

  components: { HomeHero, AboutSection, ArticlesSection, BlogSection },
};
</script>
