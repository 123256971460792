<template>
  <div id="create-comment" class="col-lg-6 col-md-8 mx-auto">
    <v-btn
      v-if="!loggedIn"
      color="error"
      tile
      block
      to="login"
      class="text-capitalize"
      >{{ loginText }}</v-btn
    >
    <v-textarea
      v-model="comment"
      v-if="loggedIn"
      name="comments"
      label="Add your comment"
      id="comments-field"
      clearable
      append-outer-icon="mdi-send"
      @click:append-outer="send"
      :loading="loading"
    ></v-textarea>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      :color="error ? 'error' : 'success'"
    >
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import {
  addDoc,
  collection,
  getFirestore,
  Timestamp,
} from "@firebase/firestore";
import { mapGetters } from "vuex";
export default {
  name: "CreateComment",
  data() {
    return {
      comment: "",
      loginText: "Login to Comment",
      loggedIn: localStorage.loggedIn === "true",
      snackbar: false,
      error: false,
      message: "",
      loading: false,
    };
  },
  methods: {
    async send() {
      if (!this.loggedIn) {
        this.$router.push({ name: "Login" });
        scrollTo(0, 0);
        return;
      }

      this.loading = true;
      const blogId = this.$router.currentRoute.query.id;
      const db = getFirestore();
      const ref = collection(db, `blogs/${blogId}/comments`);

      const data = {
        created_by: this.user.uid,
        author: this.user.displayName,
        created_at: Timestamp.now(),
        text: this.comment,
        author_image_url: this.user.photoURL,
      };
      try {
        await addDoc(ref, data);
        this.loading = false;
        this.comment = "";
        this.error = false;
        this.message = "Your comment has been submitted";
        this.snackbar = true;
      } catch (error) {
        this.message = "Something went wrong, please try again.";
        this.snackbar = true;
        this.loading = false;
        this.error = true;
        console.log("error commenting", error);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
    }),
  },
};
</script>



