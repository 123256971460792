<template>
  <div id="delete-button">
    <v-btn
      style="z-index: 10"
      v-if="isAdmin"
      color="secondary"
      fixed
      right
      absolute
      bottom
      tile
      class="text-capitalize my-4"
      @click="dialog = !dialog"
    >
      <v-icon>mdi-delete</v-icon>
      {{ data.label }}</v-btn
    >
    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          {{ confirmDeleteText }}
        </v-card-title>
        <v-card-text>
          {{ deleteMessage }}
        </v-card-text>
        <v-card-actions>
          <v-row justify="end">
            <v-btn
              text
              tile
              class="text-capitalize"
              @click="dialog = !dialog"
              >{{ cancelText }}</v-btn
            >
            <v-btn
              text
              tile
              color="error"
              class="text-capitalize"
              @click="handleDelete"
              :loading="loading"
              >{{ deleteText }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="error" timeout="2000" color="error">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { deleteObject, getStorage, ref } from "@firebase/storage";
import { mapGetters } from "vuex";
import { deleteDoc, doc, getFirestore } from "@firebase/firestore";

export default {
  props: { data: Object },
  data() {
    return {
      loading: false,
      error: false,
      dialog: false,
      confirmDeleteText: "Delete Item",
      deleteMessage:
        "Are you sure you want to delete this item, this action cannot be undone.",
      cancelText: "Cancel",
      deleteText: "Delete",
      errorMessage: "Something went wrong,please try again",
    };
  },
  methods: {
    async handleDelete() {
      await this.deleteItem();
      await this.deleteImage();
      this.loading = false;
      this.$router.go(-1);
    },
    async deleteImage() {
      const imageUrl = this.data.imageUrl;
      if (!imageUrl) return;
      this.loading = true;
      try {
        const storage = getStorage();
        const imageRef = ref(storage, imageUrl);
        deleteObject(imageRef);
      } catch (error) {
        console.log(error);
        this.error = true;
        return;
      }
    },
    async deleteItem() {
      try {
        const db = getFirestore();
        const docRef = doc(db, this.data.ref);
        await deleteDoc(docRef);
      } catch (error) {
        console.log(error);
      }
    },
  },

  computed: {
    ...mapGetters({
      isAdmin: "isAdmin",
    }),
  },
};
</script>

