<template>
  <div id="join">
    <v-container class="col-lg-6">
      <HeroForm />
    </v-container>
  </div>
</template>

<script>
import HeroForm from "@/components/home/HeroForm.vue";

export default {
  components: { HeroForm },
};
</script>

