import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Articles from '@/views/Articles.vue'
import Blogs from '@/views/Blogs.vue'
import Article from '@/views/Article.vue'
import CreateArticle from '@/views/CreateArticle.vue'
import CreateBlog from '@/views/create-blog.vue'
import Blog from '@/views/Blog.vue'
import Join from '@/views/Join.vue'
import Login from '@/views/Login.vue'
import Profile from '@/views/Profile.vue'
import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/articles',
        name: 'Articles',
        component: Articles
    },
    {
        path: '/blogs',
        name: 'Blogs',
        component: Blogs
    },
    {
        path: '/article',
        name: 'Article',
        component: Article
    },
    {
        path: '/create-article',
        name: 'CreateArticle',
        component: CreateArticle,
        meta: { requiresAuth: true }
    },
    {
        path: '/blog',
        name: 'Blog',
        component: Blog
    },
    {
        path: '/create-blog',
        name: 'CreateBlog',
        component: CreateBlog,
        meta: { requiresAuth: true }
    },
    {
        path: '/join',
        name: 'Join',
        component: Join
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
})

router.beforeEach((to, from, next) => {
    if ( to.meta.requiresAuth && localStorage.loggedIn !== 'true') next({ name: 'Login' })
    else next()
  })

export default router