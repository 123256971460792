<template>
  <v-card id="hero-form" elevation="0">
    <v-card-title primary-title>
      {{ joinText }}
    </v-card-title>
    <v-card-text>
      <v-col>
        <v-text-field
          v-model="name"
          name="name"
          :label="nameText"
          id="name"
          :rules="rules"
        ></v-text-field>
        <v-text-field
          v-model="phone"
          name="phone"
          :label="phoneText"
          :rules="rules"
          id="phone"
          required
        ></v-text-field>
        <v-text-field
          v-model="address"
          name="address"
          :label="addressText"
          :rules="rules"
          id="adress"
          required
        ></v-text-field>
        <v-card-actions>
          <v-btn
            block
            color="secondary "
            elevation="0"
            class="text-capitalize"
            :disabled="disabled"
            :loading="loading"
            @click="submit"
            >{{ submitText }}</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-card-text>
    <v-snackbar v-model="snackbar" color="success">
      {{ snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { getFirestore, collection, addDoc } from "firebase/firestore";
export default {
  name: "HeroForm",
  data() {
    return {
      name: "",
      phone: "",
      address: "",
      nameText: "Name",
      phoneText: "Phone",
      joinText: "Join The Movement",
      submitText: "Submit",
      addressText: "Address",
      loading: false,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    disabled() {
      return !this.name || !this.phone || !this.address;
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      const db = getFirestore();
      const data = {
        name: this.name,
        phone: this.phone,
        adress: this.address,
        created_at: Date.now(),
      };
      try {
        await addDoc(collection(db, "registrations"), data);
      } catch (error) {
        this.loading = false;
        console.log("error on submitting registration", error);
        this.snackbarText = "Something went wrong, please try again";
        this.snackbar = true;
      }
      this.loading = false;
      this.snackbarText = "Registration Successful, Welcome to the movement";
      this.snackbar = true;
      this.name = "";
      this.phone = "";
      this.address = "";
    },
  },
};
</script>
