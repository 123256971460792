<template>
  <div id="article">
    <Loading v-if="loading" />
    <v-img :src="article.image_url" max-height="60vh">
      <template v-slot:placeholder>
        <Placeholder />
      </template>
    </v-img>
    <v-container class="col-lg-6 mx-auto">
      <h1>{{ article.title }}</h1>
      <p class="caption mx-auto">
        {{ date }}
      </p>
      <p v-html="article.text"></p>
      <v-row class="my-6">
        <v-chip v-for="tag in article.tags" :key="tag" small class="mx-1">{{
          tag
        }}</v-chip>
      </v-row>
    </v-container>

    <v-snackbar v-model="error" color="error" timeout="2000">{{
      snackbarText
    }}</v-snackbar>
    <DeleteButton :data="deleteData" />
  </div>
</template>

<script>
import Loading from "@/layouts/Loading";
import Placeholder from "@/layouts/Placeholder";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import DeleteButton from "@/layouts/delete-button";
export default {
  name: "Article",
  components: { Loading, Placeholder, DeleteButton },
  mounted() {
    this.getArticle();
  },
  data() {
    return {
      loading: false,
      error: false,
      snackbarText: "",
      article: {},
      deleteData: {
        label: "Delete Article",
        ref: `articles/${this.$router.currentRoute.query.id}`,
      },
    };
  },
  methods: {
    async getArticle() {
      this.loading = true;
      const id = this.$router.currentRoute.query.id;
      const db = getFirestore();
      const ref = doc(db, "articles", id);
      try {
        const doc = await getDoc(ref);

        this.article = doc.data();
        this.deleteData.imageUrl = this.article.image_url;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.snackbarText = "Something went wrong,please try again.";
        this.error = true;
      }
      this.loading = false;
    },
  },
  computed: {
    date() {
      try {
        const createdAt = this.article.created_at.toDate().toDateString();
        return createdAt;
      } catch (error) {
        console.log(error);
        return "";
      }
    },
  },
};
</script>



