<template>
  <v-card
    id="blog-card"
    tile
    color="secondary"
    :to="{ path: 'blog', query: { id: `${blog.id}` } }"
    height="64vh"
    class="overflow-hidden"
  >
    <v-img
      :src="blog.image_url ? blog.image_url : logo"
      height="50vh"
      :max-width="$vuetify.breakpoint.mobile ? '100%' : '40vw'"
      ><template v-slot:placeholder> <Placeholder /> </template
    ></v-img>
    <v-col>
      <v-card-title style="word-break: normal" class="white--text">
        {{ blog.title }}
      </v-card-title>
    </v-col>
  </v-card>
</template>

<script>
import Placeholder from "@/layouts/Placeholder";

export default {
  name: "BlogCard",
  components: { Placeholder },

  props: {
    blog: Object,
  },
  data() {
    return {
      logo: require("@/assets/white-logo.svg"),
    };
  },
  computed: {
    text() {
      const limit = 300;
      if (this.blog.text.length > limit)
        return this.blog.text.substring(0, limit);
      else return this.blog.text;
    },
  },
};
</script>



