<template>
  <div id="articles">
    <Hero :title="title" :description="description" />
    <Loading :loading="loading" />
    <v-container class="my-4">
      <v-row>
        <v-col v-for="article in articles" :key="article.id" lg="4">
          <ArticleCard :article="article" />
        </v-col>
      </v-row>
      <CreateButton to="/create-article" :label="createArticleText" />
    
    </v-container>
  </div>
</template>

<script>
import Hero from "@/layouts/Hero";
import ArticleCard from "@/layouts/ArticleCard.vue";
import Loading from "@/layouts/Loading.vue";
import CreateButton from "@/layouts/create-button";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from "@firebase/firestore";
// import { mapGetters } from "vuex";
// import CreateButton from "./layouts/create-button.vue";
export default {
  name: "Articles",
  components: { Hero, ArticleCard, Loading, CreateButton },
  mounted() {
    this.getArticles();
  },
  data() {
    return {
      title: "Articles",
      description:
        "We share articles that can help you learn more about yourself and the community around you.",
      loading: true,
      limit: 10,
      articles: [],
      createArticleText: "Create Article",
    };
  },

  methods: {
    async getArticles() {
      this.loading = true;
      const db = getFirestore();
      const ref = collection(db, "articles");
      const q = query(ref, orderBy("created_at", "desc"), limit(this.limit));
      try {
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          let articleDoc = doc.data();
          articleDoc.id = doc.id;
          this.articles.push(articleDoc);
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },
  // computed: {
  //   ...mapGetters({
  //     isAdmin: "isAdmin",
  //   }),
  // },
};
</script>

