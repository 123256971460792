<template>
  <v-row id="logout" justify="center" class="my-2">
    <v-btn
      text
      tile
      color="grey"
      class="text-capitalize"
      :loading="loading"
      @click="logout"
      >{{ logoutText }}</v-btn
    >
  </v-row>
</template>

<script>
import { getAuth, signOut } from "@firebase/auth";
export default {
  data() {
    return { logoutText: "Logout", loading: false };
  },
  methods: {
    async logout() {
      this.loading = true;
      const auth = getAuth();
      await signOut(auth);
      this.$store.commit("updateLoginStatus", false);
      this.$store.commit("updateCurrentUser", {});
     
      localStorage.removeItem("loggedIn");

      this.loading = false;
      this.$router.go(-1);
    },
  },
};
</script>
