<template>
   <CreateView view="Article" path="articles" />
</template>

<script>
import CreateView from "@/layouts/create-view";

export default {
   components: { CreateView },
}
</script>

