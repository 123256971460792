<template>
  <div>
    <v-btn
      tile
      block
      :loading="loading"
      color="secondary"
      class="my-6"
      @click="login"
      >{{ google }}</v-btn
    >
    <v-snackbar v-model="error" timeout="2000" color="error" tile>
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
export default {
  name: "GoogleButtion",
  data() {
    return {
      google: "Continute with Google",
      loading: false,
      error: false,
      errorMessage: "Something went wrong, please try again",
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log("user", user);
        this.$store.commit("updateCurrentUser", user);
        this.$store.commit("udateLoginStatus", true);
        localStorage.loggedIn = true;
        this.loading = false;
        this.$router.go(-1);
      } catch (error) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log({
          "error on login in with google: error code": errorCode,
          "error message": errorMessage,
        });
        this.error = true;
        this.loading = false;
        // The email of the user's account used.
        // const email = error.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      }
    },
  },
};
</script>

