<template>
  <div id="tiptap" style="width: 100%">
    <v-row style="display: fixed" justify="center" v-if="editor" class="my-4">
      <div>
        <v-btn
          class="pa-4 font-weight-bold"
          text
          @click="editor.chain().focus().toggleBold().run()"
        >
          B
        </v-btn>
        <v-btn
          text
          class="mx-1 font-weight-bold font-italic"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          I
        </v-btn>
        <v-btn
          text
          class="mx-1 font-weight-bold text-decoration-line-through"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          S
        </v-btn>
      </div>
      <div>
        <v-btn
          text
          class="mx-1 text-capitalize"
          @click="editor.chain().focus().setParagraph().run()"
        >
          p
        </v-btn>
        <v-btn
          text
          class="mx-1"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          h1
        </v-btn>
        <v-btn
          text
          class="mx-1"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          h2
        </v-btn>
        <v-btn
          text
          class="mx-1"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          h3
        </v-btn>
        <v-btn
          text
          class="mx-1"
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        >
          h4
        </v-btn>
        <v-btn
          text
          class="mx-1"
          @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        >
          h5
        </v-btn>
        <v-btn
          text
          class="mx-1"
          @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        >
          h6
        </v-btn>
      </div>
      <v-btn
        color="black lighten-2"
        icon
        class="mx-1"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <v-icon>mdi-order-bool-ascending</v-icon>
      </v-btn>
      <v-btn
        color="black lighten-2"
        icon
        class="mx-1"
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <v-icon> mdi-order-numeric-ascending </v-icon>
      </v-btn>
    </v-row>
    <editor-content :editor="editor" class="pa-6" />
    <v-row justify="center" class="my-4" width="100vw">
      <v-col cols="6">
        <v-btn
          large
          block
          tile
          color="success"
          :loading="loading"
          class="text-capitalize col-6"
          @click="submit"
          >{{ submitText }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

export default {
  components: {
    EditorContent,
  },
  props: {
    loading: Boolean,
  },

  data() {
    return {
      editor: null,
      submitText: "Submit",
    };
  },

  mounted() {
    this.editor = new Editor({
      content: "<p>Click here to begin...</p>",
      extensions: [StarterKit],
    });
  },

  beforeDestroy() {
    this.content;
  },

  methods: {
    submit() {
      const html = this.editor.getHTML();
      this.$emit("submit", { html });
    },
  },
};
</script>
