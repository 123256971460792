<template>
  <v-container id="not-found" >
    
    <v-row justify="center" align="center">
      <v-col cols="8">

        <p>{{ notFoundText }}</p>
        <v-icon>mdi-error</v-icon>
        <router-link to="/" class="text-decodation-none">
          <v-btn>{{ homeText }}</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      notFoundText: "Sorry, Page Not Found",
      homeText: "Home",
    };
  },
};
</script>

