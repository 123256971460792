<template>
  <div id="create-view">
    <Hero :title="view" :description="description" />
    <v-container grid-list-xs>
      <v-img
        v-if="src"
        :src="src"
        contain
        max-height="50vh"
        class="my-4"
      ></v-img>
      <v-row justify="center">
        <v-col lg="8" sm="9">
          <v-file-input
            v-model="image"
            :label="addImageText"
            prepend-icon="mdi-image"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="8" sm="10">
          <v-text-field
            name="title"
            clearable
            required
            :label="titleText"
            v-model="title"
            class="col-lg-6"
          ></v-text-field>
        </v-col>
      </v-row>
      <Tiptap @submit="submit" :loading="loading" />
      <v-snackbar v-model="error" color="error" timeout="2000">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Hero from "@/layouts/Hero";
import Tiptap from "@/layouts/Tiptap.vue";
import imageCompression from "browser-image-compression";
import {
  addDoc,
  collection,
  getFirestore,
  Timestamp,
} from "@firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  components: { Hero, Tiptap },
  props: { view: String, path: String },

  data() {
    return {
      addImageText: "Add an Image",
      image: null,
      error: false,
      loading: false,
      errorMessage: "Something went wrong, please try again",
      titleText: "Title",
      title: "",
      description: `Create a new ${this.view}`,
    };
  },

  methods: {
    async submit(text) {
      if (!text.html || !this.title) return;
      this.loading = true;
      const uid = this.$store.getters.currentUser.uid;
      let imageUrl = "";
      if (this.image != null) {
        this.compressImage();
        imageUrl = await this.uploadImage();
      }
      const data = {
        title: this.title,
        image_url: imageUrl,
        text: text.html,
        created_by: uid,
        created_at: Timestamp.now(),
      };

      const db = getFirestore();
      try {
        const docRef = await addDoc(collection(db, `${this.path}`), data);
        const id = docRef.id;
        this.loading = false;
        this.$router.replace({
          name: `${this.view}`,
          query: { id: id },
        });
      } catch (error) {
        this.loading = false;
        this.error = true;
        console.log(error);
      }
    },

    async compressImage() {
      const imageFile = this.image;
      const options = {
        maxSizeMB: 0.12,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        this.image = compressedFile;
      } catch (error) {
        console.log(error);
      }
    },

    async uploadImage() {
      const storage = getStorage();
      const metadata = {
        contentType: "image/jpeg",
      };
      const storageRef = ref(
        storage,
        "images/" + `msichana-ni-tai-${Date.now()}.jpg`
      );
      let url;
      try {
        await uploadBytes(storageRef, this.image, metadata);
        url = await getDownloadURL(storageRef);
      } catch (error) {
        console.log(error);
      }

      return url;
    },
  },
  computed: {
    src() {
      if (this.image) return URL.createObjectURL(this.image);
      else return "Select an Image";
    },
  },
};
</script>

