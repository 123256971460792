<template>
  <div id="login">
    <Hero :title="title" :description="description" />
    <v-container class="col-lg-6">
      <GoogleButton />
      <p class="caption text-center mx-auto font-italic">
        {{ userAgreement }}
      </p>
      <!-- <v-tabs color="primary" slider-color="primary">
        <v-tab>
          {{ loginText }}
        </v-tab>
        <v-tab>
          {{ signupText }}
        </v-tab>
        <v-tab-item>
          <LoginTab />
        </v-tab-item>
        <v-tab-item> <SignupTab /> </v-tab-item>
      </v-tabs> -->
    </v-container>
  </div>
</template>

<script>
import Hero from "@/layouts/Hero";
// import LoginTab from "@/components/login/LoginTab";
// import SignupTab from "@/components/login/SignupTab";
import GoogleButton from "@/components/login/GoogleButton.vue";
export default {
  name: "Login",
  components: {
    Hero, //LoginTab, SignupTab,
    GoogleButton,
  },
  data() {
    return {
      title: "Login",
      description: "Please login to continue.",
      loginText: "Login",
      signupText: "Signup",
      userAgreement:
        "By using our platform, you agree to follow our guidelines.",
    };
  },
};
</script>


