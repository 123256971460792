<template>
  <v-container id="about-section">
    <v-row justify="center" align="center" class="my-6 py-6">
      <v-col>
        <h1 style="word-break: normal">{{ title }}</h1>
        <p>{{ description }}</p>
        <v-btn
          tile
          color="secondary"
          class="text-capitalize"
          elevation="0"
          to="/about"
          >{{ learnMoreText }}</v-btn
        >
      </v-col>
      <v-col>
        <v-img
          :src="imgSrc"
          :width="$vuetify.breakpoint.mobile ? '100%' : '40vw'"
          :height="$vuetify.breakpoint.mobile ? '100%' : '40vw'"
          alt="About Image"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutSection",
  data() {
    return {
      imgSrc: require("@/assets/img/msichana_ni_tai_07.jpg"),
      title: "About Msichana Ni Tai",
      description:
        "Msichana ni Tai is also known as The Eagle Movement. This movement fights the socialization and internalization that women and girls are weak, that they are chickens, instead it boldly claims that “women and girls are eagles not chickens.”  This Girls Movement follows what the late President Julius Kambarage Nyerere wrote in 1944 while studying at Makerere University.",
      learnMoreText: "Learn More",
    };
  },
};
</script>


