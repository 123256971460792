<template>
  <CreateView view="Blog" path="blogs" />
</template>

<script>
import CreateView from "@/layouts/create-view";
export default {
  components: { CreateView },
};
</script>

