<template>
  <div id="blog">
    <Loading v-if="loading" />
    <v-img :src="blog.image_url" max-height="60vh">
      <template v-slot:placeholder>
        <Placeholder />
      </template>
    </v-img>
    <v-container class="col-lg-6 mx-auto">
      <h1>{{ blog.title }}</h1>
      <p class="caption mx-auto">
        {{ date }}
      </p>
      <p v-html="blog.text"></p>
      <v-row class="my-6">
        <v-chip v-for="tag in blog.tags" :key="tag" small class="mx-1">{{
          tag
        }}</v-chip>
      </v-row>
    </v-container>
    <CreateComment />
    <Comments />

    <v-snackbar v-model="error" color="error" timeout="2000">{{
      snackbarText
    }}</v-snackbar>
    <DeleteButton  :data="deleteData" />
  </div>
</template>

<script>
import Loading from "@/layouts/Loading";
import Placeholder from "@/layouts/Placeholder";
import Comments from "@/components/blog/Comments";
import CreateComment from "@/components/blog/CreateComment";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import DeleteButton from "@/layouts/delete-button";
export default {
  name: "Blog",
  components: { Loading, Placeholder, Comments, CreateComment, DeleteButton },
  mounted() {
    this.getBlog();
  },
  data() {
    return {
      loading: false,
      error: false,
      snackbarText: "",
      blog: {},
      deleteData: {
        label: "Delete Blog",
        ref: `blogs/${this.$router.currentRoute.query.id}`,
      },
    };
  },
  methods: {
    async getBlog() {
      this.loading = true;
      const id = this.$router.currentRoute.query.id;
      const db = getFirestore();
      const ref = doc(db, "blogs", id);
      try {
        const doc = await getDoc(ref);
        this.blog = doc.data();
        this.deleteData.imageUrl = this.blog.image_url;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.snackbarText = "Something went wrong,please try again.";
        this.error = true;
      }
      this.loading = false;
    },
  },
  computed: {
    date() {
      try {
        const createdAt = this.blog.created_at.toDate().toDateString();
        return createdAt;
      } catch (error) {
        console.log(error);
        return "";
      }
    },
  },
};
</script>



