<template>
  <div id="blog-section">
    <v-container>
      <h1 class="text-center">{{ title }}</h1>
      <p class="text-center">{{ description }}</p>
      <v-row justify="center" class="my-4">
        <v-col v-for="blog in blogs" :key="blog.id" sm="10" lg="4">
          <BlogCard :blog="blog" />
        </v-col>
      </v-row>
      <v-row justify="center" class="my-6">
        <v-btn
          large
          tile
          class="text-capitalize"
          elevation="0"
          color="primary"
          to="/blogs"
          >{{ viewMoreText }}</v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BlogCard from "@/layouts/BlogCard";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from "@firebase/firestore";
export default {
  name: "BlogSection",
  components: { BlogCard },
  data() {
    return {
      title: "Blog",
      description: "Come and join the discusion",
      viewMoreText: "View More",
      readMore: "Read More",
      blogs: [],
    };
  },
  mounted() {
    this.getBlogs();
  },

  methods: {
    async getBlogs() {
      const db = getFirestore();
      const ref = collection(db, "blogs");
      const q = query(ref, orderBy("created_at", "desc"), limit(3));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let blogDoc = doc.data();
        blogDoc.id = doc.id;

        this.blogs.push(blogDoc);
      });
    },
  },
};
</script>

